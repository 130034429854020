<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("npg", ["territorialJurisdiction", "administrativeStructure", "isNpgMasterFetched"]),
  },

  async created() {
    if (!this.isNpgMasterFetched) {
      this.loadTerritorialJurisdictions();
      this.loadAdministrativeStructures();
      this.loadCategories();
    }
  },

  methods: {
    ...mapActions("npg", ["loadTerritorialJurisdictions", "loadAdministrativeStructures", "loadCategories"]),
  },
};
</script>
